.cardCustom {
    margin: 2px 5px;
    background-color: #05668d;
    width: 100%;
}

.cardInfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0;
    padding-top: 1rem;
}

.cardInfoItem {
    width: 33%;
}

.iconContainer {
    text-align: center;
}

.infoContainer {
    text-align: right;
}

@media (min-width: 800px) {
    .cardCustom {
        width: 100%;
        margin: 5px;
        background-color: #05668d;
    }
    
    .cardInfoContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
    }

    .cardInfoItem {
        width: 100%;
    }

    .infoContainer {
        text-align: left;
    }
}