.jumboCustom {
    width: 100%;
    background-color: #f0f3bd;
    color: #05668d;
    margin: 4vh auto 2vh;
    padding: 1rem;
}

.weatherContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: space-around;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

@media (min-width: 800px) {
    .jumboCustom {
        width: 100%;
        background-color: #f0f3bd;
        color: #05668d;
        margin: 4vh auto 2vh;
        padding: 2rem 1rem;
    }
        
    .weatherContainer {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        align-items: center;
        align-content: space-around;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }
}