.formCustom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    width: 100%;
}

.inputCustom {
    width: 200px;
}

.buttonCustom {
    margin-left: 7px;
    background-color: #05668d;
    border-color: #05668d;
}

.buttonCustom:hover,
.buttonCustom:active {
    background-color: #028090;
    border-color: #028090;
}